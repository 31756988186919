import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import jaAccount from './utils/locales/ja/account.json';
import jaAssets from './utils/locales/ja/assets.json';
import jaChats from './utils/locales/ja/chats.json';
import jaCheckLists from './utils/locales/ja/check-lists.json';
import jaCommon from './utils/locales/ja/common.json';
import jaCurrencies from './utils/locales/ja/currencies.json';
import jaDashboard from './utils/locales/ja/dashboard.json';
import jaErrors from './utils/locales/ja/errors.json';
import jaGroups from './utils/locales/ja/groups.json';
import jaImageEditor from './utils/locales/ja/image-editor.json';
import jaLogin from './utils/locales/ja/login.json';
import jaParts from './utils/locales/ja/parts.json';
import jaProducts from './utils/locales/ja/products.json';
import jaReports from './utils/locales/ja/reports.json';
import jaRequests from './utils/locales/ja/requests.json';
import jaSchedules from './utils/locales/ja/schedules.json';
import jaTask from './utils/locales/ja/task.json';
import jaToasts from './utils/locales/ja/toasts.json';
import jaUsers from './utils/locales/ja/users.json';

import enAccount from './utils/locales/en/account.json';
import enAssets from './utils/locales/en/assets.json';
import enChats from './utils/locales/en/chats.json';
import enCheckLists from './utils/locales/en/check-lists.json';
import enCommon from './utils/locales/en/common.json';
import enCurrencies from './utils/locales/en/currencies.json';
import enDashboard from './utils/locales/en/dashboard.json';
import enErrors from './utils/locales/en/errors.json';
import enGroups from './utils/locales/en/groups.json';
import enImageEditor from './utils/locales/en/image-editor.json';
import enLogin from './utils/locales/en/login.json';
import enParts from './utils/locales/en/parts.json';
import enProducts from './utils/locales/en/products.json';
import enReports from './utils/locales/en/reports.json';
import enRequests from './utils/locales/en/requests.json';
import enSchedules from './utils/locales/en/schedules.json';
import enTask from './utils/locales/en/task.json';
import enToasts from './utils/locales/en/toasts.json';
import enUsers from './utils/locales/en/users.json';

const resourcesToBundle = {
  en: {
    common: enCommon,
    errors: enErrors,
    toasts: enToasts,
    'image-editor': enImageEditor,
    currencies: enCurrencies,
    task: enTask,
    account: enAccount,
    assets: enAssets,
    chats: enChats,
    'check-lists': enCheckLists,
    dashboard: enDashboard,
    groups: enGroups,
    login: enLogin,
    parts: enParts,
    products: enProducts,
    requests: enRequests,
    schedules: enSchedules,
    users: enUsers,
    reports: enReports,
  },
  ja: {
    common: jaCommon,
    errors: jaErrors,
    toasts: jaToasts,
    'image-editor': jaImageEditor,
    currencies: jaCurrencies,
    task: jaTask,
    account: jaAccount,
    assets: jaAssets,
    chats: jaChats,
    'check-lists': jaCheckLists,
    dashboard: jaDashboard,
    groups: jaGroups,
    login: jaLogin,
    parts: jaParts,
    products: jaProducts,
    requests: jaRequests,
    schedules: jaSchedules,
    users: jaUsers,
    reports: jaReports,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: resourcesToBundle,
    fallbackLng: 'ja',
    supportedLngs: ['ja', 'en'],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'localStorage'],
      caches: ['localStorage'],
      lookupFromPathIndex: 0,
    },
    defaultNS: 'common',
    fallbackNS: ['common'],
    ns: [],
  });

export default i18n;
